import React from "react";

import { Link, StaticQuery, graphql } from "gatsby";
import _ from "lodash";
import ReactHtmlParser from "react-html-parser";
import styled from "styled-components";
import { fonts, colors, breakpoints } from "./../style-utilities/variables";
import NewsGridItem from "./News/NewsGridItem";

/**
 * This component of a 3X1 grid is used in blog/slug L3
 * as related blog articles, at /about below as "in the news", and in
 * about/news/slug L3 as "related news articles"
 */

const StyledRelatedComponent = styled.div`
  margin: 2em 0 1.5em;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-row-gap: 2rem;

  @media (min-width: ${breakpoints.tablet}) {
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 1rem;
  }
  @media (min-width: ${breakpoints.desktop}) {
    grid-column-gap: 2rem;
  }
`;

const RelatedNews = props => {
  return (
    <StaticQuery
      query={POSTS_AND_NEWS_QUERY}
      render={data => {
        //not rendering related posts just related news in this component
        //related posts component is RelatedBlogsQueryComp.jsx
        const postsLists = [...data.allWordpressPost.edges].filter(
          post => post.node.slug !== "do-not-delete"
        );

        //get news posts and remove dummy data
        const newsPosts = [...data.allWordpressWpNews.edges].filter(
          article => article.node.slug !== "do-not-delete"
        );

        // function to remove the same post from related list as the one clicked in
        const removeSameNewsPost = newsPosts.filter(function(e) {
          return e.node.slug !== props.slug;
        });

        let relatedNewsPosts = [];
        //Find news posts with same topic
        removeSameNewsPost.filter(function(e) {
          e.node.topic.filter(function(d) {
            props.topic.map(function(topic, i) {
              if (topic === d) {
                relatedNewsPosts.push(e);
              }
            });
          });
        });
        removeSameNewsPost.filter(function(e) {
          if (e.node.vertical.length > 0) {
            e.node.vertical.filter(function(d) {
              props.vertical.map(function(vertical, i) {
                if (vertical === d) {
                  relatedNewsPosts.push(e);
                }
              });
            });
          }
        });

        relatedNewsPosts.push(removeSameNewsPost);

        //function to remove duplicates that are created if post has same topic,service or vertical
        function removeDuplicates(arr) {
          const final = [];
          arr.map((e, i) => !final.includes(e) && final.push(e));
          return final;
        }

        relatedNewsPosts = removeDuplicates(relatedNewsPosts);

        return (
          <StyledRelatedComponent>
            {props.type === "post"
              ? postLists.slice(0, 3).map(({ node }) => {
                  const postExcerpt = _.truncate(node.acf.excerpt, {
                    length: 100,
                    omission: " ..."
                  });
                  return (
                    <div className="blogPost" key={node.slug} item={node}>
                      {" "}
                      <div className="date mb-2">{node.date}</div>
                      <h2>{node.title}</h2>
                      <span className="excerpt">
                        {ReactHtmlParser(postExcerpt)}
                      </span>
                      <div className="read-more">
                        <Link to={"/insights/blog/" + node.slug}>
                          read more
                        </Link>{" "}
                      </div>
                    </div>
                  );
                })
              : relatedNewsPosts.slice(0, 3).map(({ node }) => {
                  if (node){
                    return (
                      <NewsGridItem
                        className="newsPost"
                        key={node.slug}
                        item={node}
                      />
                    );
                  }
                })}
          </StyledRelatedComponent>
        );
      }}
    />
  );
};

const POSTS_AND_NEWS_QUERY = graphql`
  query {
    allWordpressWpNews {
      edges {
        node {
          title
          topic
          vertical
          type
          date(formatString: "MMMM D, YYYY")
          slug
          content
          acf {
            excerpt
          }
        }
      }
    }
    allWordpressPost {
      edges {
        node {
          slug
          title
          topic
          type
          content
          date(formatString: "MMMM D, YYYY")
          author {
            name
            slug
          }
          acf {
            excerpt
          }
          better_featured_image {
            source_url
            description
            caption
          }
        }
      }
    }
  }
`;
export default RelatedNews;

// it can if we just pass in a list of "items" from the query of the page its on.
//  if we use it on the blog L3 page, we'd have a list of related stuff coming in from the blog L3 query, we can pass that into the component
