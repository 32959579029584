import React, { Fragment, Component } from 'react';
import { Link, graphql } from 'gatsby';
import format from 'date-fns/format';
import { colors, breakpoints, fonts } from '../../style-utilities/variables';
import ReactHtmlParser from 'react-html-parser';
import _ from 'lodash';
import styled from 'styled-components';

//COMPS
import MediaContactWidget from '../../components/MediaContactWidget';
import ShareWidget from '../../components/ShareWidget';
import RelatedNews from '../../components/RelatedNews';
import NavBar from '../../components/NavBar';
import Hero from '../../components/Hero';
import ContactBanner from '../../components/ContactBanner';
import ButtonRed from '../../components/ButtonRed';
import SEO from '../../components/SEO';
import DefaultSharingImage from './../../static/ansira-logo-sharing.png';

const StyledNewsPostContainer = styled.main`
  margin: 0.75em auto;
  margin-top: 0;
  max-width: 1500px;
  .info-holder {
    p {
      @media (max-width: ${breakpoints.mobileMax}) {
        display: none;
      }
    }
    h1 {
      @media (max-width: ${breakpoints.mobileMax}) {
        font-size: 32px;
      }
    }
  }

  .news-area {
    margin-top: 2rem;
    display: flex;
    @media (max-width: ${breakpoints.tablet}) {
      grid-template-columns: 1fr;
      /* grid-row-gap: 2rem; */
      flex-direction: column;
    }
  }

  /*WIDGETS*/
  .share-widget {
    display: flex;
    flex: 1 1 auto;
    justify-content: center;

    div[role='button'] {
      display: flex;
      justify-content: center;
      cursor: pointer;
    }
    @media (min-width: ${breakpoints.tablet}) {
      justify-content: flex-start;
      flex-direction: column;
    }
    .socialIcons {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .media-widget {
    font-weight: bold;
    padding: 1em 2em;
    border-radius: 0.25rem;
    margin: 0 0 2rem;
    background-image: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    p {
      margin-bottom: 0;
    }
    h2 {
      display: none;
    }
  }
`;
const StyledNewsPost = styled.div`
  flex-grow: 2;
  @media (min-width: ${breakpoints.desktop}) {
    padding: 0 10%;
  }
  p {
    color: ${colors.primaryGray};
  }
  h1 {
    font-size: 36px;
    font-weight: 500;
    @media (min-width: ${breakpoints.tablet}) {
      font-size: 48px;
      font-weight: 600;
    }
  }
  .press {
    text-transform: uppercase;
    font-size: 0.7rem;
    @media (min-width: ${breakpoints.tablet}) {
      font-size: 16px;
    }
  }
  .date {
    color: ${colors.primaryGray};
  }
`;
const StyledRelatedNews = styled.div`
  background: ${colors.secondaryGray};
  padding-top: 1em;
  padding-bottom: 1em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  h2 {
    color: ${colors.black};
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    margin: 0;
    margin: 1em auto;

    @media (min-width: ${breakpoints.desktop}) {
      font-size: 28px;
    }
  }
  span {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    margin: 0;
    margin: 1em auto;

    @media (min-width: ${breakpoints.desktop}) {
      -ms-flex-item-align: start;
      align-self: flex-start;
    }
  }
`;

class NewsPostPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewPayload: null,
      isPreviewMode: false
    };
  }

  componentDidMount() {
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    /*
    Preview Mode -------------------------------------
    */
    if (params.get('preview')) {
      let id = params.get('id');
      //let wpnonce = params.get('_wpnonce');
      let BASE_URL = `https://api.dev.ansira.io/wp-json/v1/preview/`;
      fetch(`${BASE_URL}${id}`, {
        mode: 'cors',
        credentials: 'include'
      })
        .then(response => {
          return response.json();
        })
        .then(myJson => {
          if (myJson) {
            this.setState({
              isPreviewMode: true,
              previewPayload: myJson
            });
          }
        });
    }
    /*
    -------------------------------------------------
    */
  }

  render() {
    let post = this.state.previewPayload;

    if (post) {
      post.topic = [28, 27];
      post.services = [20];
      post.vertical = [37, 72];
    }

    return post ? (
      <StyledNewsPostContainer>
        <NavBar link="/about/news" name="Press Room" />
        <Hero
          headline={post.post_title}
          sub={post.acf.hero_sub_headline}
          textcolor="white"
          bgcolor="black"
        />
        <div className="page-container">
          <div className="news-area">
            <StyledNewsPost>
              <p className="date">{this.formattedDate}</p>
              <div className="postContent">
                {ReactHtmlParser(post.post_content)}
              </div>
            </StyledNewsPost>
            <ShareWidget className="share-widget" />
          </div>
          <MediaContactWidget
            mediaBodyText="If you have questions or need additional information, email "
            className="media-widget"
            textcolor={colors.black}
            bgcolor="none"
          />
        </div>
        <StyledRelatedNews className="page-container">
          <h2>More Articles Like This</h2>
          <RelatedNews
            type="news"
            slug={post.slug}
            topic={post.topic}
            services={post.our_solutions}
            vertical={post.vertical}
          />
          <ButtonRed pageLink="/about/news" textLabel="See All News" />
        </StyledRelatedNews>
        <br />
        <br />
        {/* <ContactBanner /> */}
      </StyledNewsPostContainer>
    ) : null;
  }
}

export default NewsPostPreview;
