import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { colors, fonts, breakpoints } from "../style-utilities/variables";

const StyledMediaContactBg = styled.div`
  background-color: ${props => props.bgcolor};
  position: relative;
  border: 2px solid ${colors.secondaryGray};
  padding: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  display: -ms-flexbox;
  -ms-flex-pack: center;
  h2 {
    font-size: 26px;
  }
  @media (min-width: ${breakpoints.tablet}) {
    .copy-container {
      width: 100%;
    }
    p {
      max-width: 240px;
      display: inline-block;
      margin: 0 auto;

    }
  }
`;

const MediaContactWidget = ({
  className,
  textcolor,
  mediaBodyText,
  ...remProps
}) => {
  const linkStyle = {
    color: textcolor
  };
  return (
    <StyledMediaContactBg
      style={{ color: textcolor }}
      className={className}
      {...remProps}
    >
      <h2>Press Contact</h2>
      <div className="copy-container">
        <p>
          {mediaBodyText ||
            "If you’re a member of the media and you need something specific, drop us a line at"}{" "}
          <a href="mailto:pr@ansira.com" style={linkStyle}>
            pr@ansira.com
          </a>
          .
        </p>
      </div>
    </StyledMediaContactBg>
  );
};

export default MediaContactWidget;
